import {
  useDeleteFilesMutation,
  useUploadFileMutation,
} from '@gen2/api/files/hooks';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { TInviteRequestFile } from '@gen2/api/invite-requests/api';
import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import { useConfirmationModalStore } from '@gen2/app/components/confirmation-modal/hooks/confirmation-modal-store';
import { useFileStore } from '@gen2/app/components/invite-request/hooks/file-store';
import { SetupPlugin } from '@gen2/app/components/rich-text-editor/plugins/SetupPlugin/SetupPlugin';
import RichTextEditor from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { TInviteRequestsForm } from '@gen2/app/invites/send-invites/requests/requests';
import { MAX_MESSAGE_LENGTH } from '@gen2/app/invites/send-invites/requests/schema';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { Alert, FormHelperText, Stack } from '@mui/material';
import { DragAndDrop, formatSize, renderIcon } from '@nx-fe/components';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRequestHook } from '../../hooks/request';
import { RequestContacts } from '../../request-contact/request-contact';
import {
  StyledAttachments,
  StyledAttachmentsLabel,
  StyledFileUploadRequest,
} from '../file-upload/file-upload.styled';
import { MAX_FILE_COUNT, formValidationSchema } from '../file-upload/schema';
import LiveFormControls from './live-form-controls';

export type TFileUploadRequestForm = {
  rawMessage?: string;
  message: string;
  meta: string;
};

export type TLiveFormRequestProps = {
  id: number;
};

export const LiveFormRequest = React.memo(({ id }: TLiveFormRequestProps) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<TInviteRequestsForm>();
  const { onUpdate, req } = useRequestHook(id);
  const siStore = useSendInviteStore();

  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const { mutate: fileUploadMutation } = useUploadFileMutation();
  const { isLoading: isFileDeleting } = useDeleteFilesMutation();
  const { setIsConfirmationModalOpen } = useConfirmationModalStore();
  const { setFile } = useFileStore();
  const [fileErrors, setFileErrors] = useState<string[]>();
  const toast = useToast();

  const { t } = useTranslation('sendInvite');

  const onMessageChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      const root = $getRoot();

      const test = root.getTextContent();
      const html = $generateHtmlFromNodes(editor);

      if (test.length === 0) {
        setValue(`requests.${id}.meta`, '');
        setValue(`requests.${id}.description`, '');
      } else {
        setValue(`requests.${id}.meta`, JSON.stringify(editorState.toJSON()));
        setValue(`requests.${id}.description`, html);
      }
    });
  };

  const onEditorSetup = (editor: LexicalEditor) => {
    const state = editor.getEditorState();

    state.read(() => {
      const rawHTML = $generateHtmlFromNodes(editor);

      setValue(`requests.${id}.description`, rawHTML);
    });
  };

  const onFileUpload = async (files: File[]) => {
    if (req?.id === undefined) return;

    setIsUploadLoading(true);

    // should not allow to upload if max files reached
    if (req.files.length >= MAX_FILE_COUNT) {
      siStore.setUpgradeModal({
        isOpen: true,
        title: t('request.fileUpload.upgrade.title') ?? '',
        description: t('request.fileUpload.upgrade.description') ?? '',
      });
      setIsUploadLoading(false);
      return;
    }

    // should not accept multiple files
    if (files.length > 1) {
      toast.show({
        text: t('request.fileUpload.message.onlyOneFile') ?? '',
        variant: 'error',
      });
      setIsUploadLoading(false);
      return;
    }

    const file = files[0];

    try {
      await formValidationSchema.validate({ file }, { abortEarly: false });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setFileErrors(err.errors);
      setIsUploadLoading(false);
      return;
    }

    fileUploadMutation(
      {
        type: 'request',
        typeId: req.id,
        file,
      },
      {
        onSuccess: () => {
          toast.show({
            text: t('request.fileUpload.message.uploaded') ?? '',
            variant: 'success',
          });

          queryClient.invalidateQueries([InviteRequestsKeys.getInviteRequests]);
          queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);
          queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (err: any) => {
          toast.show({
            text: t('request.fileUpload.message.error') ?? '',
            variant: 'error',
          });
        },
        onSettled: () => setIsUploadLoading(false),
      },
    );
  };

  const clearError = (errorMessage: string) => {
    setFileErrors((prev) => prev?.filter((err) => err !== errorMessage));
  };

  const handleDelete = (file: TInviteRequestFile) => {
    if (isFileDeleting) return;

    setFile({
      ...file,
      request: req,
    });

    setIsConfirmationModalOpen(true);
  };

  const formTypeErrorDescription: string = useMemo(() => {
    if (fileErrors && fileErrors?.includes('request.liveForm.file.type')) {
      return t('request.liveForm.file.type');
    }

    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileErrors]);

  const contacts = useMemo(() => {
    if (req.contacts) {
      return req.contacts;
    }

    return [];
  }, [req]);

  return (
    <StyledFileUploadRequest key={req.id}>
      <RequestContacts request={req} contacts={contacts} />

      <div>
        <RichTextEditor
          onBlur={onUpdate}
          placeholder={t('request.liveForm.message.placeholder') ?? ''}
        >
          <OnChangePlugin onChange={onMessageChange} />
          <SetupPlugin
            onSetup={onEditorSetup}
            maxLength={MAX_MESSAGE_LENGTH}
            defaultEditorState={req.meta}
          />
        </RichTextEditor>
        {(errors?.requests && errors.requests[id])?.description?.message && (
          <FormHelperText error>
            {t(errors.requests[id]?.description?.message ?? '')}
          </FormHelperText>
        )}
      </div>
      {!!req?.files?.length && (
        <div>
          <StyledAttachmentsLabel>
            <span>Form</span>
          </StyledAttachmentsLabel>
          <StyledAttachments>
            {req.files
              .filter((file) => file.type === 'send_invite_attachment')
              .map((file) => (
                <LiveFormControls
                  key={file.id}
                  progress={100}
                  fileId={file.id}
                  fileName={file.original_name}
                  fileSize={`(${formatSize(file.size)})`}
                  isUploading={false}
                  icon={renderIcon(file.mime_type)}
                  onDelete={() => handleDelete(file)}
                  data-cy={`file-${file.id}`}
                />
              ))}
          </StyledAttachments>
        </div>
      )}
      <Stack rowGap={2}>
        {!!fileErrors?.length &&
          fileErrors.map((err) => {
            if (err === 'request.liveForm.file.type') {
              return null;
            }

            return (
              <Alert key={err} severity="error" onClose={() => clearError(err)}>
                {t(err)}
              </Alert>
            );
          })}
        {!req.files.filter((file) => file.type === 'send_invite_attachment')
          .length && (
          <DragAndDrop
            isLoading={isUploadLoading}
            onUpload={onFileUpload}
            accept=".pdf"
            description={formTypeErrorDescription}
          />
        )}
      </Stack>
    </StyledFileUploadRequest>
  );
});
