// TODO: On edit draft, parse the JSON message and set the rawMessage state
import { useInviteQuery } from '@gen2/api/invites/hooks';
import UpgradeModal from '@gen2/app/components/modals/upgrade-modal';
import { EMPTY_EDITOR_STATE } from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { useAuth, useRouter } from '@gen2/hooks';
import { TContact } from '@gen2/types/contact';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { get } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffectOnce, useUnmount } from 'usehooks-ts';
import { ActionModal } from '../../components/action-modal/action-modal';
import { BannerMessage } from './banner-message';
import { ContactPopups } from './contact-popups';
import { Editor } from './editor';
import { EmailPreview } from './email-preview';
import { preventDefault } from './helpers';
import { useSendInviteApi } from './hooks';
import InviteHead from './invite-head';
import { Portal } from './portal';
import Reminder from './reminder/reminder';
import { saveDraftSchema } from './schema';
import {
  StyledSendInvites,
  StyledSendInvitesBody,
} from './send-invites.styled';
import { useSendInviteStore } from './store';
import { Templates } from './templates/templates';

dayjs.extend(utc);
dayjs.extend(timezone);

export type TDraftInviteForm = {
  from_user_id: string;
  due_at: Dayjs | null;
  subject?: string;
  rawMessage?: string;
  message?: string;
  contacts?: TContact[];
  meta?: string;
  overdue_reminder?: string;
  customOverdueValue?: string;
  almost_due_reminder?: string;
  customAlmostDueValue?: string;
};

export const defaultValues: TDraftInviteForm = {
  from_user_id: '',
  due_at: null,
  subject: '',
  rawMessage: '',
  message: '',
  contacts: [],
  overdue_reminder: '4',
  almost_due_reminder: '1',
  customAlmostDueValue: '',
  customOverdueValue: '',

  // empty editor state
  meta: EMPTY_EDITOR_STATE,
};

const SendInvites = () => {
  const store = useSendInviteStore();
  const almostDueAnchor = useRef<HTMLButtonElement>(null);
  const { user } = useAuth();
  const params = useParams<{ id: string; }>();
  const router = useRouter();
  const currentInviteId = useMemo(() => {
    // for new invite draft & updating invite both.
    return store.contextInviteIdForNewInvite || params.id || '';
  }, [params, store.contextInviteIdForNewInvite]);

  const { data: invite } = useInviteQuery(currentInviteId, {
    enabled: Boolean(currentInviteId),
  });

  const methods = useForm<TDraftInviteForm>({
    defaultValues: {
      ...defaultValues,
      from_user_id: user?.id ?? '',
    },
    resolver: yupResolver(saveDraftSchema),
  });
  const { onInitDraft } = useSendInviteApi();

  const { reset } = methods;

  useEffectOnce(() => {
    // request for draft invite and fill invite into current context
    // only for new invite draft
    if (router.pathname === '/send-invites') {
      onInitDraft();
    }
  });

  useEffect(() => {
    if (!invite) return;

    const orgTimezone = get(user, 'organisations[0].timezone');

    const isOverdueNotCustom = invite.overdue_reminder in [0, 4, 2];
    const isAlmostdueNotCustom = invite.almost_due_reminder in [0, 1, 2];

    reset({
      from_user_id: user?.id ?? '',
      subject: invite.subject ?? '',
      message: invite.message ?? '',
      due_at: invite.due_at ? dayjs(invite.due_at).tz(orgTimezone) : null,
      contacts: invite.contacts,
      overdue_reminder: isOverdueNotCustom ? invite.overdue_reminder : 'custom',
      almost_due_reminder: isAlmostdueNotCustom
        ? invite.almost_due_reminder
        : 'custom',
      customOverdueValue: isOverdueNotCustom ? '' : invite.overdue_reminder,
      customAlmostDueValue: isAlmostdueNotCustom
        ? ''
        : invite.almost_due_reminder,
    });
    store.setInvite(invite);
    // differentiates between draft and send status for editing invite
    store.setIsEditSendInvite(invite.status !== 'draft');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite, reset, user?.id]);

  useUnmount(() => {
    store.reset();
    methods.reset({
      ...defaultValues,
      from_user_id: user?.id ?? '',
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={preventDefault} noValidate>
        <StyledSendInvites data-cy="si">
          <StyledSendInvitesBody>
            <BannerMessage />
            <InviteHead almostDueAnchor={almostDueAnchor} />
            {/* <Tabs /> */}
            <Editor />
            <Portal />
            <EmailPreview />
          </StyledSendInvitesBody>
          <ContactPopups />
          <Reminder anchorEl={almostDueAnchor.current} />
          {/* <InviteRequestsLibrary /> */}
        </StyledSendInvites>
        <UpgradeModal
          open={store.upgradeModal.isOpen}
          onClose={() => store.setUpgradeModal({ isOpen: false })}
          title={store.upgradeModal.title}
        >
          {store.upgradeModal.description}
        </UpgradeModal>

        <ActionModal />

        <Templates />
      </form>
    </FormProvider>
  );
};

export default SendInvites;
