import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DialogContent } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { Trans, useTranslation } from 'react-i18next';
import {
  ActionModalActions,
  ActionModalContainer,
  ActionModalTitle,
} from './action-modal.styled';
import { useActionModalStore } from './store';

export const ActionModal = () => {
  const {
    header,
    message,
    rawMessage,
    translationNamespace,
    messageParams,
    closeButtonLabel = 'Close',
    closeButtonColor = 'tertiary',
    submitButtonLabel,
    submitButtonColor = 'danger',
    submitModal,
    isModalOpen,
    closeModal,
  } = useActionModalStore();

  const { t } = useTranslation(translationNamespace);

  const onSubmit = async () => {
    submitModal();
  };

  return (
    <ActionModalContainer
      data-cy="delete-dialog"
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="delete"
      aria-describedby="delete"
      disablePortal={false}
    >
      <ModalCloseButton
        data-cy="delete-dialog-close"
        aria-label="close"
        onClick={() => closeModal(false)}
      >
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <ActionModalTitle>{header}</ActionModalTitle>
      <DialogContent dividers>
        <p>
          {
            rawMessage
              ? rawMessage
              : (
                <Trans
                  components={{ strong: <strong />, br: <br /> }}
                  t={t}
                  ns={translationNamespace}
                  i18nKey={message}
                  values={messageParams}
                />
              )
          }
        </p>
      </DialogContent>
      <ActionModalActions>
        {closeButtonLabel && (
          <Button
            variant="outlined"
            color={closeButtonColor}
            onClick={() => closeModal(false)}
          >
            {closeButtonLabel}
          </Button>
        )}
        {submitButtonLabel && (
          <Button
            variant="contained"
            color={submitButtonColor}
            onClick={onSubmit}
          >
            {submitButtonLabel}
          </Button>
        )}
      </ActionModalActions>
    </ActionModalContainer>
  );
};
