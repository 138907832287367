import {
  InviteRequestsKeys,
  useUpdateInviteRequestMutation,
} from '@gen2/api/invite-requests/hooks';
import { TInviteRequestsForm } from '@gen2/app/invites/send-invites/requests/requests';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const useRequestHook = (id: number) => {
  const {
    formState: { isValid },
    getValues,
    trigger,
  } = useFormContext<TInviteRequestsForm>();
  const req = getValues().requests[id];

  const { mutate: updateInviteRequestMutation } =
    useUpdateInviteRequestMutation();
  // const { data: pdfRedactions } = usePDFRedactionsQuery();
  const siStore = useSendInviteStore();
  const { inviteId } = useParams<{ inviteId: string }>();
  const { t } = useTranslation('sendInvite');

  const onUpdate = () => {
    trigger(`requests.${id}`);

    if (!isValid) return;

    // cancel previous request
    queryClient.cancelQueries([InviteRequestsKeys.updateInviteRequest]);

    // eslint-disable-next-line unused-imports/no-unused-vars
    const { pdf_redactions, ...payload } = req;

    /* const pdfRedactionsIds = pdfRedactions?.reduce((acc, item) => {
      for (const selected of pdf_redactions) {
        // NOTE: change these to id if the requirement says name can be duplicated
        if (selected.name === item.name) {
          acc.push(item.id);
        }
      }

      return acc;
    }, [] as string[]); */

    updateInviteRequestMutation(
      {
        inviteId: inviteId || siStore.invite.id,
        ...payload,
        // pdf_redactions: pdfRedactionsIds,
      },
      {
        onSuccess: () => {
          siStore.setBannerMessage({
            severity: 'success',
            message: siStore.isEditSendInvite
              ? t('notDraftSaved')
              : t('draftSaved'),
          });
        },
      },
    );
  };

  return {
    onUpdate,
    req,
  };
};
