import { TAddContactErrorResponse } from '@gen2/api/contacts/api';
import {
  ContactKeys,
  useAddContactMutation,
  useEditContactMutation,
} from '@gen2/api/contacts/hooks';
import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import {
  InvitesKeys,
  useAddContactsToInviteMutation,
} from '@gen2/api/invites/hooks';
import AddContactPopup, {
  AddContactPopupProps,
  TAddContactForm,
} from '@gen2/app/components/contact-popup/add-contact/add-contact';
import ContactPopup from '@gen2/app/components/contact-popup/contact-popup';
import { queryClient } from '@gen2/config';
import { TContact } from '@gen2/types/contact';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createContactPayload } from './helpers';
import { MAX_CONTACT_LIMIT } from './schema';
import { TDraftInviteForm } from './send-invites';
import { useSendInviteStore } from './store';

export const ContactPopups = () => {
  const store = useSendInviteStore();
  const { watch } = useFormContext<TDraftInviteForm>();
  const {
    mutate: addContactsToInviteMutation,
    isLoading: isAddContactsToInviteLoading,
  } = useAddContactsToInviteMutation();
  const { mutate: addContactMutation, isLoading: isAddContactLoading } =
    useAddContactMutation();

  const { mutate: editContactMutation, isLoading: isEditContactLoading } =
    useEditContactMutation();
  const { t } = useTranslation('sendInvite');

  const wContacts = watch('contacts');

  const sendToIds = useMemo(() => {
    if (!wContacts) return [];

    return wContacts.map((item) => item.id);
  }, [wContacts]);

  const onCloseContactList = () => {
    store.setIsContactListOpen(false);
  };

  const openContact = (
    e: React.MouseEvent,
    mode: 'add' | 'edit',
    contact: TContact | null,
  ) => {
    e.stopPropagation();
    store.setisContactOpen(true);
    store.setIsContactListOpen(false);
    store.setMode(mode);
    store.setCurrentContact(contact);
  };

  const closeContact = () => {
    store.setisContactOpen(false);
  };

  const selectSendTo = useCallback(
    (contact: TContact) => {
      const newContacts = [...(wContacts || []), contact];
      const currentContactsLength = newContacts.length;

      // if the user has reached the limit of contacts he can send to
      // we open the upgrade modal
      if (currentContactsLength > MAX_CONTACT_LIMIT) {
        store.setUpgradeModal({
          isOpen: true,
          title: t('contacts.upgrade.title') ?? '',
          description: t('contacts.upgrade.description') ?? '',
        });
        return;
      } else {
        store.setIsContactListOpen(false);
        store.set({ isSendFromLoading: true });

        // AFTER DRAFT:
        // we call the API to directly add the contact to the list
        addContactsToInviteMutation(
          {
            inviteId: store.invite.id,
            contacts: [{ id: contact.id }],
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([InvitesKeys.getInvite]);
              await queryClient.invalidateQueries([InviteRequestsKeys.getInviteRequests]);

              store.set({ isSendFromLoading: false });
              store.setBannerMessage({
                severity: 'success',
                message: store.isEditSendInvite
                  ? t('editSendInvite.addContactToEditInvite')
                  : '',
              });
            },
            onError: () => {
              store.set({ isSendFromLoading: false });
            },
          },
        );
      }
    },
    [addContactsToInviteMutation, store, t, wContacts],
  );

  const resolveContactList = (contacts: TContact[]) => {
    if (!contacts.length) return [];

    return contacts.map((contact) => {
      if (sendToIds.includes(contact.id)) {
        return { ...contact, disabled: true };
      }
      return contact;
    });
  };

  const onCreateContact: AddContactPopupProps['onCreate'] = ({
    contact,
    form,
  }) => {
    addContactMutation(createContactPayload(contact), {
      onSuccess: async (res): Promise<void> => {
        const { contact } = res.data.data;

        // pre-select contact
        selectSendTo(contact);

        closeContact();

        await queryClient.invalidateQueries({
          queryKey: [ContactKeys.getContacts],
        });
      },
      onError: (error: unknown) => {
        const { data: { errors } } = error as { data: TAddContactErrorResponse; };

        if (errors) {
          Object.keys(errors).forEach((key) => {
            const perFieldErrors = errors[key as keyof TAddContactErrorResponse['errors']];

            if (!perFieldErrors) return;

            form.setError(key as keyof TAddContactForm, {
              type: 'manual',
              message: perFieldErrors[0],
            });
          });
        }
      },
    });
  };

  const onEditContact: AddContactPopupProps['onEdit'] = ({ contact, form }) => {
    editContactMutation(
      {
        contact_id: store.currnetContact?.id ?? '',
        data: createContactPayload(contact),
      },
      {
        onSuccess: async () => {
          closeContact();

          // invalidate getContacts
          await queryClient.invalidateQueries({
            queryKey: [ContactKeys.getContacts],
          });
          await queryClient.invalidateQueries({
            queryKey: [InvitesKeys.getInvite],
          });
        },
        onError: (error: unknown) => {
          const { data } = error as { data: TAddContactErrorResponse; };

          if (data?.errors) {
            const errors = data.errors;

            Object.keys(errors).forEach((key) => {
              const fieldErrors =
                errors[key as keyof TAddContactErrorResponse['errors']];

              if (!fieldErrors) return;

              form.setError(key as keyof TAddContactForm, {
                type: 'manual',
                message: fieldErrors[0],
              });
            });
          }
        },
      },
    );
  };

  return (
    <>
      <ContactPopup
        anchorEl={store.contactPopupAnchorEl}
        id="contact-list"
        open={store.isContactListOpen}
        onOpenContact={openContact}
        onSelectContact={selectSendTo}
        onClickAway={onCloseContactList}
        resolveItems={resolveContactList}
        loading={isAddContactsToInviteLoading}
      />
      <AddContactPopup
        onCancel={closeContact}
        onCreate={onCreateContact}
        onEdit={onEditContact}
        anchorEl={store.contactPopupAnchorEl}
        id="contact-create"
        open={store.isContactOpen}
        onClickAway={closeContact}
        isAddContactLoading={isAddContactLoading || isEditContactLoading}
        mode={store.mode}
        currentContact={store.currnetContact}
      />
    </>
  );
};
