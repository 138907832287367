import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteFilesMutation } from '@gen2/api/files/hooks';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { TInviteRequest } from '@gen2/api/invite-requests/api';
import {
  InviteRequestsKeys,
  useUpdateInviteRequestMutation,
} from '@gen2/api/invite-requests/hooks';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { TInviteRequestsForm } from '@gen2/app/invites/send-invites/requests/requests';
import { useRequestsStore } from '@gen2/app/invites/send-invites/requests/store';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import {
  AccordionProps,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { isUndefined } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useConfirmationModalStore } from '../confirmation-modal/hooks/confirmation-modal-store';
import {
  StyledErrorBox,
  StyledIconButton,
  StyledInviteRequest,
  StyledInviteRequestBody,
  StyledInviteRequestHead,
  StyledTitle,
  StyledType,
  StyledTypesContainer,
} from './invite-request.styles';
import { FileUploadRequest } from './variant/file-upload/file-upload';
import { LiveFormRequest } from './variant/live-form/live-form';

export type RequestType = {
  label: string;
  value: string;
  icon: IconDefinition;
  component: React.FC<{
    id: number;
  }>;
};

export type RequestTypes = Array<RequestType>;

const types: RequestTypes = [
  {
    label: 'File Upload',
    value: 'file-upload',
    icon: regular('upload'),
    component: FileUploadRequest,
  },
  {
    label: 'Form',
    value: 'live-form',
    icon: regular('pencil'),
    component: LiveFormRequest,
  },
];

export type InviteRequestProps = {
  hasDelete?: boolean;
  isDeleteDisabled?: boolean;
  id: number; // index
} & Omit<AccordionProps, 'children' | 'id'>;

export const InviteRequest = React.memo(
  ({ hasDelete, isDeleteDisabled, id }: InviteRequestProps) => {
    const {
      formState: { errors, isValid },
      getValues,
      setValue,
      register,
      trigger,
    } = useFormContext<TInviteRequestsForm>();

    const req = getValues().requests[id];

    const { setToDeleteRequest, activeRequest, setActiveRequest } =
      useRequestsStore();
    const { mutate: deleteFilesMutation } = useDeleteFilesMutation();
    const { setIsConfirmationModalOpen } = useConfirmationModalStore();
    const { showModal } = useActionModalStore();
    const { inviteId } = useParams<{ inviteId: string }>();

    const defaultRequestType = useMemo(() => {
      return types.find((t) => t.value === req?.type) ?? types[0];
    }, [req]);

    const [requestType, setRequestType] =
      useState<RequestType>(defaultRequestType);

    const siStore = useSendInviteStore();
    const { mutate: updateInviteRequestMutation } =
      useUpdateInviteRequestMutation();

    const { t } = useTranslation('sendInvite');
    const stopPropagation = useMemo(() => {
      return (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        e.stopPropagation();
      };
    }, []);

    const handleDelete = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();

        setToDeleteRequest(req);
        setIsConfirmationModalOpen(true);
      },
      [req, setToDeleteRequest, setIsConfirmationModalOpen],
    );

    const onSelectType = async (
      event: SelectChangeEvent,
      req: TInviteRequest,
    ) => {
      event.stopPropagation();

      const selectedType =
        types.find((t) => t.value === event.target.value) ?? types[0];
      if (selectedType.value === requestType.value) return;

      const handleUpdate = async (isConfirmed: boolean) => {
        if (!isConfirmed) return;

        setRequestType(selectedType);
        setValue(`requests.${id}.type`, selectedType.value);

        // Prepare payload
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { pdf_redactions, ...payload } = req;
        const placeholderTitle = `New ${selectedType.label} Request`;
        const placeholderTitleRegex = /^New (File Upload|Form) Request$/g;
        const updatedTitle = placeholderTitleRegex.test(req.title)
          ? placeholderTitle
          : req.title;

        updateInviteRequestMutation(
          {
            inviteId: inviteId || siStore.invite.id,
            ...payload,
            pdf_redactions: [],
            title: updatedTitle,
            type: selectedType.value,
          },
          {
            onSuccess: async () => {
              siStore.setBannerMessage({
                severity: 'success',
                message: siStore.isEditSendInvite
                  ? t('notDraftSaved')
                  : t('draftSaved'),
              });

              setValue(`requests.${id}.pdf_redactions`, []);
              queryClient.invalidateQueries([
                InviteRequestsKeys.getInviteRequests,
              ]);
              queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);
              queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);
            },
          },
        );

        // Clear all files
        // if there are no files uploaded dont call this endpoint when changing request type
        // and wont call this endpoint when edit send invite, as deletion will be handled on BE
        if (req.files.length > 0 && !siStore.isEditSendInvite) {
          deleteFilesMutation({
            type: 'request',
            typeId: req.id,
            files: req.files.map((file) => ({ id: file.id })),
          });
        }
      };

      if (siStore.isEditSendInvite) {
        const { isConfirmed } = await showModal({
          header: t('editSendInvite.changeType.title'),
          message: 'editSendInvite.changeType.message',
          translationNamespace: 'sendInvite',
          closeButtonLabel: t('editSendInvite.changeType.cancel') ?? '',
          submitButtonLabel: t('editSendInvite.changeType.confirm') ?? '',
        });

        if (!isUndefined(isConfirmed)) {
          await handleUpdate(isConfirmed);
        }
      } else {
        await handleUpdate(true);
      }
    };

    const onUpdate = async () => {
      trigger(`requests.${id}.title`);

      if (!isValid) return;

      // eslint-disable-next-line unused-imports/no-unused-vars
      const { pdf_redactions, ...payload } = req;

      queryClient.cancelQueries([InviteRequestsKeys.updateInviteRequest]);

      updateInviteRequestMutation(
        {
          inviteId: inviteId || siStore.invite.id,
          ...payload,
        },
        {
          onSuccess: async () => {
            siStore.setBannerMessage({
              severity: 'success',
              message: siStore.isEditSendInvite
                ? t('notDraftSaved')
                : t('draftSaved'),
            });
          },
        },
      );
    };

    const onToggleRequest = useCallback(() => {
      if (activeRequest?.id === req.id) {
        setActiveRequest(null);
      } else {
        setActiveRequest(req);
      }
    }, [activeRequest?.id, req, setActiveRequest]);

    return (
      <>
        <StyledInviteRequest
          $hasError={!!(errors?.requests && errors.requests[id])}
          square
          disableGutters
          expanded={activeRequest?.id === req?.id}
          onChange={onToggleRequest}
          data-cy="invite-request"
        >
          <StyledInviteRequestHead
            expandIcon={
              <FontAwesomeIcon icon={regular('chevron-right')} size="sm" />
            }
          >
            <StyledTitle
              fullWidth
              variant="filled"
              size="small"
              onClick={stopPropagation}
              {...register(`requests.${id}.title`, {
                onBlur: onUpdate,
              })}
              data-cy={`request-title-${id}`}
              inputProps={{
                'data-cy': 'request-title-input',
              }}
            />
            <StyledTypesContainer onClick={stopPropagation}>
              <FormControl fullWidth color="info" size="small">
                <InputLabel id="request-type">Request Type</InputLabel>
                <Select
                  data-cy="request-type-menu"
                  inputProps={{
                    'data-cy': 'request-type',
                  }}
                  fullWidth
                  label="Request Type"
                  value={requestType.value}
                  onChange={(event) => onSelectType(event, req)}
                >
                  {types.map((t) => (
                    <StyledType key={t.value} value={t.value}>
                      {t.label}
                    </StyledType>
                  ))}
                </Select>
              </FormControl>
            </StyledTypesContainer>
            {hasDelete && (
              <Tooltip title="Delete" arrow placement="top">
                <StyledIconButton
                  onClick={handleDelete}
                  disabled={isDeleteDisabled}
                  color="tonalLight"
                >
                  <FontAwesomeIcon icon={regular('trash-can')} size="sm" />
                </StyledIconButton>
              </Tooltip>
            )}
          </StyledInviteRequestHead>
          <StyledInviteRequestBody>
            <requestType.component id={id} />
          </StyledInviteRequestBody>
        </StyledInviteRequest>
        {!!errors.requests && errors.requests[id]?.title && (
          <StyledErrorBox data-cy="error">
            {t(errors.requests[id]?.title?.message || '')}
          </StyledErrorBox>
        )}
      </>
    );
  },
);
