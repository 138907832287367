import { ButtonPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { noop } from 'lodash';
import { create } from 'zustand';

type TShowModalPayload = {
  isConfirmed?: boolean;
};

export type TShowModalParams = {
  header: string;
  message?: string;
  rawMessage?: string;
  translationNamespace?: string;
  messageParams?: Record<string, string>;
  closeButtonLabel?: string;
  closeButtonColor?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;
  submitButtonLabel?: string;
  submitButtonColor?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;
};

export interface IActionModalStore extends TShowModalParams {
  isModalOpen: boolean;
  closeModal: (isModalOpen: boolean) => void;
  submitModal: () => void;
  showModal: (params: TShowModalParams) => Promise<TShowModalPayload>;
}

export const defaultActionModalState: Omit<IActionModalStore, 'showModal'> = {
  isModalOpen: false,

  header: '',
  message: '',
  rawMessage: '',
  translationNamespace: 'sendInvite',
  messageParams: {},
  closeButtonLabel: '',
  closeButtonColor: 'tertiary',
  submitButtonLabel: '',
  submitButtonColor: 'danger',

  closeModal: noop,
  submitModal: noop,
};

export const useActionModalStore = create<IActionModalStore>()((set) => ({
  ...defaultActionModalState,

  // actions
  showModal: async (params: TShowModalParams) => {
    const res = await new Promise((resolve) => {
      set({
        header: params.header,
        message: params.message,
        rawMessage: params.rawMessage ?? '',
        translationNamespace: params.translationNamespace ?? 'sendInvite',
        messageParams: params.messageParams,
        closeButtonLabel: params.closeButtonLabel,
        closeButtonColor: params.closeButtonColor,
        submitButtonLabel: params.submitButtonLabel,
        submitButtonColor: params.submitButtonColor,
        isModalOpen: true,
        closeModal: () => {
          set((prevState) => ({
            ...prevState,
            isModalOpen: false,
          }));

          resolve({
            isConfirmed: false,
          });
        },
        submitModal: () => {
          set((prevState) => ({
            ...prevState,
            isModalOpen: false,
          }));

          resolve({
            isConfirmed: true,
          });
        },
      });
    });

    return res as TShowModalPayload;
  },
}));
